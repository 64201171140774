.bank--login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10rem;
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bank--label-style {
  color: #25314c !important;
  font-size: 0.9rem !important;
  font-weight: 600 !important;
}

.bank--input-style {
  border-color: #a8adb7 !important;
  height: 2.5rem;
}

.bank--input-style::placeholder {
  color: #a8adb7 !important;
  opacity: 1 !important;
}

.bank--input-style:focus {
  border-color: #a8adb7 !important;
}

.bank--login-header {
  text-align: center;
}

.bank--login-header h2 {
  font-size: 4rem;
  color: white;
  font-weight: 700;
}

.bank--login-header span {
  font-size: 1.5rem;
  color: white;
  font-weight: 500;
}

.bank--login-form h3 {
  font-size: 1.5rem;
  color: #25314c;
  font-weight: 700;
  margin-bottom: 3rem;
}

.bank--login-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 30%;
  height: 50%;
}

.login-button {
  width: 100% !important;
  background-color: #0051cc !important;
  border-color: #0051cc !important;
  padding: 1.2rem;
  font-size: 1.1rem;
  font-weight: 600;
  margin-top: 1rem;
}

.login-button:hover {
  background-color: #0052ccd7 !important;
  transition: all 0.5s ease;
}

/* Loader */

/* HTML: <div class="loader"></div> */
.loader {
  width: 10px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}
@keyframes l5 {
  0% {
    box-shadow: 20px 0 #fff, -20px 0 rgba(255, 255, 255, 0.541);
    background: #fff;
  }
  33% {
    box-shadow: 20px 0 #fff, -20px 0 rgba(255, 255, 255, 0.541);
    background: rgba(255, 255, 255, 0.541);
  }
  66% {
    box-shadow: 20px 0 rgba(255, 255, 255, 0.541), -20px 0 #fff;
    background: rgba(255, 255, 255, 0.541);
  }
  100% {
    box-shadow: 20px 0 rgba(255, 255, 255, 0.541), -20px 0 #fff;
    background: #fff;
  }
}
